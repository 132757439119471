import { Button, VStack, Img, Text, Progress, Link, AspectRatio, useColorModeValue } from "@chakra-ui/react";
import { Link as NextLink } from "@chakra-ui/next-js";
import { useMemo } from "react";
import { useTranslation } from "next-i18next";
import { gql } from "graphql-request";
import { localizeDigits } from "@/common/utils/string";
import { CollegeCardFragment } from "@/gql-types";
import { urls } from "@/reverse/urls";

interface CollegeCardProps {
  pao: CollegeCardFragment;
}

export const CollegeCard = ({ pao }: CollegeCardProps) => {
  const { t } = useTranslation("college", { keyPrefix: "landpage" });
  const cardUrl = useMemo(() => {
    if (pao.college.has_enabled_new_landpage_for_registered_user) {
      return urls["college:college_landpage:landpage_index"](pao.college.pk, pao.college.url_name);
    }
    return urls["college:college_landpage"](pao.college.pk, pao.college.url_name);
  }, [pao.college.has_enabled_new_landpage_for_registered_user, pao.college.pk, pao.college.url_name]);

  const cardImageUrl = useColorModeValue(pao.college.card_image_url, pao.college.dark_normal_card_image_url);

  return (
    <Button
      border="1px solid transparent"
      _hover={{
        textDecoration: "none",
        transform: "translateY(-4px)",
        borderColor: "gray.200",
      }}
      _active={{ backgroundColor: "none" }}
      as={pao.college.has_enabled_new_landpage_for_registered_user ? NextLink : Link}
      display="flex"
      href={cardUrl}
      flexDirection={["column", null, "row"]}
      w="full"
      background="bg.pure"
      borderRadius={{ base: "8px", md: "12px" }}
      p={{ base: "12px", md: "20px" }}
      h="full"
      columnGap="12px"
    >
      <AspectRatio height="full" ratio={1.5} width="210px" mb={{ base: "12px", md: 0 }}>
        <Img
          color="gray.800"
          w="full"
          src={cardImageUrl}
          borderRadius="10px"
          objectFit="cover"
          mb={{ base: "12px", md: "10px" }}
          alt={pao.college.name}
        />
      </AspectRatio>
      <VStack
        mt="0px !important"
        w={{ base: "full", md: "65%" }}
        h="full"
        alignItems="start"
        justifyContent="space-between"
      >
        <Text
          mb={{ base: "4px", md: "initial" }}
          w="full"
          fontSize="md"
          fontWeight="700"
          lineHeight={{ base: "150%", md: "120%" }}
          whiteSpace="break-spaces"
          color="gray.800"
        >
          {pao.college.name}
        </Text>

        <VStack color="gray.800" w="full" alignItems="start">
          <Text whiteSpace="nowrap" fontSize="xs" fontWeight="normal" lineHeight="150%" as="span">
            {t("Progress percent", { percent: localizeDigits(pao.passed_percent) })}
          </Text>
          <Progress
            backgroundColor="gray.100"
            colorScheme="green"
            value={pao.passed_percent}
            borderRadius="3px"
            w="full"
            size="sm"
            sx={{
              "div[role=progressbar]": {
                bg: "green.500",
              },
            }}
          />
        </VStack>
      </VStack>
    </Button>
  );
};

CollegeCard.fragment = gql`
  fragment CollegeCardFragment on PAOObjectType {
    passed_percent
    is_suspended
    college {
      pk
      name
      card_image_url
      dark_normal_card_image_url
      url_name
      has_enabled_new_landpage_for_registered_user
    }
  }
`;
