import { Box, Button, chakra, Container, FormControl, Input, Stack, Text, useToast, VStack } from "@chakra-ui/react";
import { SubmitHandler } from "react-hook-form";
import { axiosClient } from "apis/rest/axios-client";
import { useTranslation } from "next-i18next";
import { maxLengthValidator, requiredValidator, useCreateForm } from "@/common/form";
import { urls } from "@/reverse/urls";

interface InformMeFormData {
  email: string;
}

const ChakraForm = chakra("form");
const InternationalCollegeInputBox = () => {
  const toast = useToast();
  const informMeForm = useCreateForm<InformMeFormData>({ useFormProps: {} });
  const { t } = useTranslation("college", { keyPrefix: "plp.lead" });

  const onSubmit: SubmitHandler<InformMeFormData> = async (values) => {
    try {
      const response = await axiosClient.post(urls["international:international_college:inform_me_api"](), values);
      if (response.status === 201) {
        toast({ description: t("submitSuccessMessage"), status: "success" });
      }
    } catch (error) {
      informMeForm.onDrfError({ error });
    }
  };

  return (
    <ChakraForm
      width="full"
      maxWidth={{ base: "315px", lg: "415px" }}
      border="1px solid"
      borderColor="gray.600"
      borderRadius="12px"
      boxShadow="0px 10px 40px rgba(0, 0, 0, 0.16);"
      background="transparent"
      backdropFilter="blur(97.5px)"
      padding="20px"
      height="auto"
      marginTop={{ base: "25px !important", md: "initial !important" }}
      onSubmit={informMeForm.handleSubmit(onSubmit, informMeForm.onClientSideError)}
    >
      <FormControl isRequired isInvalid={!!informMeForm.formState.errors.email}>
        <Input
          height={{ base: "40px", md: "48px" }}
          placeholder={t("email")}
          type="email"
          borderColor="gray.500"
          color="gray.500"
          fontSize={{ base: "md", md: "lg" }}
          _placeholder={{
            color: "gray.500",
            fontSize: { base: "md", md: "lg" },
          }}
          {...informMeForm.register("email", {
            required: requiredValidator(true),
            maxLength: maxLengthValidator(100),
          })}
        />
      </FormControl>
      <Button
        fontSize={{ base: "md", md: "lg" }}
        height={{ base: "40px", md: "48px" }}
        fontWeight="bold"
        mt="16px"
        width="full"
        isDisabled={informMeForm.formState.isSubmitting}
        isLoading={informMeForm.formState.isSubmitting}
        type="submit"
      >
        {t("submit")}
      </Button>
    </ChakraForm>
  );
};

export const InternationalCollegeLead = () => {
  const { t } = useTranslation("college", { keyPrefix: "plp.lead" });

  return (
    <Stack
      justifyContent={{ md: "space-evenly" }}
      flexDirection={{ base: "column", md: "row" }}
      paddingBlock={{ base: "20px", md: "50px" }}
      width="full"
      columnGap="20px"
      alignItems="center"
      position="relative"
      overflow="hidden"
      backgroundColor={{ base: "gray.800", md: "transparent" }}
      marginTop={{ base: "40px", md: "initial" }}
    >
      <Box width="full" backgroundColor={{ base: "transparent", md: "gray.800" }} zIndex="1">
        <Container
          position="relative"
          maxWidth="container.xl"
          px={[5, null, 4]}
          paddingBlock={{ base: "24px", md: "20px" }}
        >
          <Stack
            zIndex={2}
            justifyContent={{ md: "space-evenly" }}
            flexDirection={{ base: "column", md: "row" }}
            width="full"
            columnGap="20px"
            alignItems="center"
            position="relative"
          >
            <VStack width="full" rowGap={{ base: "20px", md: "15px" }} alignItems="flex-start">
              <Text
                marginInline={{ base: "auto", md: "initial" }}
                fontWeight="bold"
                fontSize={{ base: "xl", md: "3xl", lg: "4xl" }}
                textAlign={{ base: "center", md: "right" }}
                color="gray.50"
              >
                {t("header")}
              </Text>
              <Text
                color="gray.50"
                width="full"
                textAlign={{ base: "center", md: "right" }}
                fontSize={{ base: "md", md: "lg" }}
              >
                {t("subheader")}
              </Text>
            </VStack>
            <InternationalCollegeInputBox />
          </Stack>
        </Container>
      </Box>
    </Stack>
  );
};
