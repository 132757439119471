import {
  VStack,
  Text,
  HStack,
  SimpleGrid,
  GridItem,
  Img,
  Box,
  Divider,
  Link,
  Button,
  AspectRatio,
  Flex,
  Spacer,
  useColorModeValue,
} from "@chakra-ui/react";
import { mdiAccountGroupOutline } from "@mdi/js";
import { Link as NextLink } from "@chakra-ui/next-js";
import { gql } from "graphql-request";
import { BuyStateFragment, InternationalCollegePLPIndexQuery } from "@/gql-types";
import { localizeDigits } from "@/common/utils/string";
import { MdIcon } from "@/common/icons/MdIcon";
import { urls } from "@/reverse/urls";

interface TagTextProps {
  canUseLandscapeMode: boolean;
  content: string;
}

const TagText = ({ canUseLandscapeMode, content }: TagTextProps) => (
  <Box
    _before={{
      content: '""',
      display: "inline-block",
      position: "absolute",
      width: 0,
      height: 0,
      right: "-5px",
      top: "0px",
      "border-bottom": "4px solid var(--chakra-colors-orange-500)",
      "border-right": "5px solid transparent",
    }}
    borderRadius="3px 0px 5px 5px"
    position="absolute"
    bg="orange.400"
    top="-4px"
    py="14px"
    px="2px"
    right={{ base: "20px", md: canUseLandscapeMode ? "48px" : "32px" }}
    zIndex={1}
  >
    <Text
      sx={{
        "writing-mode": "vertical-rl",
        "text-orientation": "mixed",
      }}
      transform="rotate(180deg)"
      fontSize="md"
      fontWeight="bold"
      color="bg.pure"
    >
      {content}
    </Text>
  </Box>
);

const BuyState = ({ buy_state: buyState }: BuyStateFragment) => (
  <Box borderRadius="md" p="0 8px" bg={`${buyState?.colorScheme}.50`} display={{ base: "none", md: "flex" }}>
    {buyState.icon && (
      <Box w="14px" h="14px">
        <Img objectFit="contain" w="100%" h="100%" src={buyState?.icon} />
      </Box>
    )}
    <Text color={`${buyState?.colorScheme}.900`} fontSize={{ base: "xs", md: "sm" }} fontWeight="400">
      {buyState?.text}
    </Text>
  </Box>
);

BuyState.fragment = gql`
  fragment BuyStateFragment on TrackCollegeType {
    buy_state {
      colorScheme
      text
      icon
    }
  }
`;

const InternationalCollegeCard = ({
  college,
}: {
  college: InternationalCollegePLPIndexQuery["international"]["colleges"][number];
}) => {
  const { tag_text: tagText } = college;
  const normalCardImageUrl = useColorModeValue(college.card_image_url, college.dark_normal_card_image_url);
  return (
    <GridItem
      colSpan={1}
      h="full"
      background="bg.pure"
      borderRadius="12px"
      transition="transform .4s ease"
      _hover={{
        boxShadow: "0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05)",
        transform: "translateY(-4px)",
        border: "1px solid",
        borderColor: "gray.700",
      }}
    >
      <Button
        padding="16px"
        bg="transparent"
        _hover={{ bg: "transparent", textDecoration: "none" }}
        _active={{ bg: "transparent" }}
        h="full"
        width="full"
        as={college.has_enabled_new_landpage_for_registered_user ? NextLink : Link}
        href={urls["international:international_college:pdp_landpage"](college.pk, college.url_name)}
        position="relative"
      >
        {!!tagText && <TagText content={tagText} canUseLandscapeMode={false} />}
        <HStack w="full" h="full" spacing={5}>
          <VStack w="full" h="full" alignItems="start" justifyContent="space-between">
            <VStack w="full" alignItems="start">
              <AspectRatio zIndex={0} width="full" ratio={1.7}>
                <Img
                  color="gray.50"
                  alt={college.name}
                  w="full"
                  src={normalCardImageUrl}
                  borderRadius="12px"
                  mb="8px"
                  objectFit="cover"
                />
              </AspectRatio>

              <Text
                width="full"
                textAlign={["center", null, "right"]}
                whiteSpace="break-spaces"
                fontSize="md"
                color="gray.50"
                fontWeight="700"
              >
                {college.name}
              </Text>
            </VStack>
            <VStack marginTop="0px !important" w="full" pt={{ base: 0, md: 3 }} alignItems="start">
              <Divider
                borderColor="gray.700"
                borderWidth="1px"
                marginBlock={{ base: "16px !important", md: "20px 8px !important" }}
              />
              <Flex w="full" alignItems="center">
                <HStack>
                  <MdIcon color="gray.50" boxSize={6} icon={mdiAccountGroupOutline} />
                  <Text fontSize="sm" color="gray.200" textAlign="center" fontWeight="700">
                    {localizeDigits(college.user_count)}
                  </Text>
                </HStack>
                <Spacer />
              </Flex>
            </VStack>
          </VStack>
        </HStack>
      </Button>
    </GridItem>
  );
};

InternationalCollegeCard.fragment = gql`
  fragment InternationalCollegeCardTrackCollegeFragment on TrackCollegeType {
    title
    ...BuyStateFragment
    tags
    college {
      id
      pk
      name
      name_description
      url_name
      user_count
      has_enabled_new_landpage_for_registered_user
      tag_text
      card_image_url
      light_large_card_image_url
      dark_normal_card_image_url
      dark_large_card_image_url
    }
    feature(first: 30) {
      edges {
        node {
          icon
          title
          id
        }
      }
    }
    level {
      id
    }
    tracks(first: 30) {
      edges {
        node {
          id
        }
      }
    }
  }
  ${BuyState.fragment}
`;

interface CollegeCardsProps {
  colleges: InternationalCollegePLPIndexQuery["international"]["colleges"];
}

export const InternationalCollegeCards = ({ colleges }: CollegeCardsProps) => (
  <VStack w="full" alignItems="flex-start">
    <SimpleGrid w="100%" gap="1.5rem" columns={{ base: 1, md: 3 }}>
      {colleges.map((college) => (
        <InternationalCollegeCard key={college.pk} college={college} />
      ))}
    </SimpleGrid>
  </VStack>
);
