import { Box, Container } from "@chakra-ui/react";
import { gql } from "graphql-request";
import { useEffect } from "react";
import { MyColleges } from "@/modules/college/plp/components/my-colleges/MyColleges";
import { SeoSection } from "@/modules/college/plp/components/seo/Seo";
import { resetColorMode } from "@/modules/college/common/theme";
import { usePlpData, CollegePLPContextProps } from "@/modules/college/plp/context";
import { InternationalCollegeAllColleges } from "./sections/InternationalCollegeAllColleges";
import { InternationalCollegeLead } from "@/modules/international/college/pages/sections/InternationalCollegeLead";
import { InternationalCollegeMenu } from "@/modules/international/college/pages/sections/InternationalCollegeMenu";
import { InternationalCollegeHero } from "@/modules/international/college/pages/sections/InternationalCollegeHero";
import { InternationalCollegeComments } from "@/modules/international/college/pages/sections/InternationalCollegeComments";
import { InternationalFooter } from "@/modules/international/common/components/InternationalFooter";
import { Banner } from "@/modules/college/plp/components/Banner";
import { InternationalCollegePLPIndexQuery } from "@/gql-types";

interface InternationalCollegeIndexProps {
  comments: InternationalCollegePLPIndexQuery["international"]["college_comments"];
  seoContent: InternationalCollegePLPIndexQuery["international"]["seo_content"];
  colleges: InternationalCollegePLPIndexQuery["international"]["colleges"];
  myColleges: InternationalCollegePLPIndexQuery["college"]["plp"]["my_colleges"];
}

const InternationalCollegeIndex = ({ comments, seoContent, colleges, myColleges }: InternationalCollegeIndexProps) => {
  const {
    plp: { banner_is_active: bannerIsActive },
  }: CollegePLPContextProps = usePlpData();
  const { seo } = seoContent;

  useEffect(() => {
    resetColorMode(); // to decide based on system preferences
  }, []);

  return (
    <>
      {bannerIsActive && <Banner />}
      <Container maxWidth="container.xl" px={[5, null, 4]} paddingBlock={{ base: "24px", md: "50px" }}>
        <InternationalCollegeMenu />
        <InternationalCollegeHero />
      </Container>
      {myColleges.length > 0 && <MyColleges my_colleges={myColleges} />}
      <InternationalCollegeAllColleges colleges={colleges} />
      <Box paddingBottom={{ base: "76px", md: "90px" }} paddingTop={{ base: "50px", md: "50px" }}>
        <InternationalCollegeComments comments={comments} />
      </Box>
      <InternationalCollegeLead />
      <Box>
        <SeoSection seo={seo} />
      </Box>
      <InternationalFooter bgColor="gray.800" />
    </>
  );
};

export const internationalPLPIndexFragment = gql`
  fragment InternationalPLPIndexFragments on CollegePLPType {
    ...MyCollegesPlpFragment
    ...BannerFragment
  }
  ${SeoSection.fragments.collegeComponent}
  ${MyColleges.fragments.collegePLP}
  ${Banner.fragment.collegePLP}
`;

InternationalCollegeIndex.fragment = {
  landpage: internationalPLPIndexFragment,
};
export { InternationalCollegeIndex };
