import { chakra } from "@chakra-ui/react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { MiniMyCollegesFragment } from "@/gql-types";
import { gql } from "graphql-request";
import { CollegeCard } from "./CollegeCard";

const ChakraSwiper = chakra(Swiper);

export const MiniMyColleges = ({ my_colleges: myColleges }: MiniMyCollegesFragment) => (
  <ChakraSwiper
    width="full"
    sx={{
      "&>div>.swiper-college": {
        borderRadius: "8px",
        width: "235px",
      },
    }}
    spaceBetween={12}
    slidesPerView="auto"
  >
    {myColleges?.map((pao) => (
      <SwiperSlide className="swiper-college" key={pao.college.pk}>
        <CollegeCard pao={pao} />
      </SwiperSlide>
    ))}
  </ChakraSwiper>
);

MiniMyColleges.fragment = gql`
  fragment MiniMyCollegesFragment on CollegePLPType {
    my_colleges {
      ...CollegeCardFragment
    }
  }
  ${CollegeCard.fragment}
`;
