import React from "react";
import { Button, Text, Heading, HStack, VStack, GridItem, SimpleGrid } from "@chakra-ui/react";
import NextImage from "next/image";
import { useTranslation } from "next-i18next";
import CodeImg from "@/modules/college/plp/assets/images/code.png";
import { useSSRBreakpointValue } from "@/common/hooks/useSSRBreakpointValue";

const HeroActionButtons = () => {
  const { t } = useTranslation("college", { keyPrefix: "plp.hero" });

  return (
    <SimpleGrid pt={[10, null, 5]} gap={3} width={["90%", null, "500px"]} columns={{ base: 1, md: 2 }}>
      <GridItem>
        <Button
          minWidth="fit-content"
          size={{ base: "md", md: "lg" }}
          w="100%"
          maxW={["unset", null, "260px"]}
          onClick={() => {
            document.getElementById("all-colleges").scrollIntoView({ behavior: "smooth" });
          }}
        >
          {t("viewAllCourses")}
        </Button>
      </GridItem>
    </SimpleGrid>
  );
};

export const InternationalCollegeHero = () => {
  const isMobile = useSSRBreakpointValue([true, null, null, false]);
  const { t } = useTranslation("college", { keyPrefix: "plp.hero" });

  return (
    <HStack
      overflow="hidden"
      w="full"
      position="relative"
      minHeight={isMobile ? "initial" : "fit-content"}
      flexDirection={["column", null, null, "row"]}
      mt={[12, null, null, 0]}
    >
      <VStack
        textAlign={["center", null, null, "right"]}
        alignItems={["center", null, null, "start"]}
        w={["100%", null, null, "55%"]}
        spacing={6}
      >
        <Text color="gray.50" as="h2" fontSize={["60px", null, "72px"]} fontWeight="700">
          {t("header")}
        </Text>
        <Heading as="h1" color="gray.50" fontSize={["20px", null, "30px"]} fontWeight="700">
          {t("subheader")}
        </Heading>
        {!isMobile && <HeroActionButtons />}
      </VStack>
      <NextImage unoptimized loading="eager" alt="hero image" src={CodeImg.src} width={550} height={426} />
      {isMobile && <HeroActionButtons />}
    </HStack>
  );
};
