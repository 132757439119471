import { Heading, VStack, HStack, Box, Container } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { InternationalCollegePLPIndexQuery } from "@/gql-types";
import { InternationalCollegeCards } from "../../components/InternationalCollegeCards";

interface InternationalCollegeAllCollegesProps {
  colleges: InternationalCollegePLPIndexQuery["international"]["colleges"];
}

export const InternationalCollegeAllColleges = ({ colleges }: InternationalCollegeAllCollegesProps) => {
  const { t } = useTranslation("college", { keyPrefix: "plp.allCourses" });

  return (
    <VStack id="all-colleges" scrollMargin={0} bg="gray.900" w="full" alignItems="start">
      <Container maxWidth="container.xl" px={[5, null, 4]} paddingBlock={{ base: "24px", md: "50px" }}>
        <HStack columnGap={{ md: "4px" }} id="all-colleges" mb={8}>
          <Box w="19px" h="19px" background="brand.400" transform="rotate(-21.66deg)" />
          <Heading color="gray.50" fontSize={{ base: "xl", md: "4xl" }} fontWeight="700">
            {t("header")}
          </Heading>
        </HStack>
        <InternationalCollegeCards colleges={colleges} />
      </Container>
    </VStack>
  );
};
