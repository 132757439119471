import { Box, Button, chakra, Container, Img, Text, useColorModeValue, VStack } from "@chakra-ui/react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import SwiperCore, { Navigation, Swiper as SwiperType } from "swiper";
import React, { MutableRefObject, useMemo, useRef } from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { useTranslation } from "next-i18next";
import { useSSRBreakpointValue } from "@/common/hooks/useSSRBreakpointValue";
import BorderImage from "@/modules/college/plp/assets/images/comment-border.png";
import QuotMarkImage from "@/modules/college/plp/assets/images/quotation-mark.png";
import { InternationalCollegeCommentType } from "@/gql-types";

const ChakraSwiper = chakra(Swiper);

const NavigationButton = ({
  onClick,
  side,
}: {
  onClick: SwiperCore["slideNext"] | SwiperCore["slidePrev"];
  side: "left" | "right";
}) => {
  const iconColor = useColorModeValue("gray.500", "gray.400");
  return (
    <Button
      width={{ base: "32px !important", md: "50px !important" }}
      height={{ base: "32px", md: "50px" }}
      minWidth={{ base: "32px !important", md: "50px !important" }}
      borderRadius="50%"
      zIndex={1}
      onClick={() => onClick()}
      position="absolute"
      {...{ [side]: { base: "20px", md: "0px" } }}
      top="50%"
      padding="0px;"
      backgroundColor={useColorModeValue("gray.200", "gray.700")}
      _hover={{
        backgroundColor: useColorModeValue("gray.300", "gray.600"),
      }}
      _active={{
        backgroundColor: useColorModeValue("gray.300", "gray.600"),
      }}
      aria-label="more comments"
    >
      {side === "left" && <ChevronLeftIcon color={iconColor} />}
      {side === "right" && <ChevronRightIcon color={iconColor} />}
    </Button>
  );
};

const NavigationButtons = ({ swiperRefObject }: { swiperRefObject: MutableRefObject<SwiperType> }) => (
  <>
    <NavigationButton side="right" onClick={() => swiperRefObject.current.slidePrev()} />

    <NavigationButton side="left" onClick={() => swiperRefObject.current.slideNext()} />
  </>
);

interface InternationalCollegeCommentsProps {
  comments: InternationalCollegeCommentType[];
}
export const InternationalCollegeComments = ({ comments }: InternationalCollegeCommentsProps) => {
  const isMini = useSSRBreakpointValue([true, null, false], { ssr: true });
  const isMedium = useSSRBreakpointValue([false, null, true, false], { ssr: true });
  const swiperRef = useRef<SwiperType>();
  const { t } = useTranslation(["college"], { keyPrefix: "plp.comments" });

  const slidesPerViews = useMemo(() => {
    if (isMedium) {
      return 2;
    }
    if (isMini) {
      return 1;
    }
    return 3;
  }, [isMini, isMedium]);

  const borderColor = useColorModeValue("gray.500", "gray.400");

  return (
    <VStack bg="bg.pure" overflow="hidden" position="relative" width="full">
      <Text pb={{ base: "8px", md: "12px" }} fontSize={{ sm: "xl", md: "4xl" }} fontWeight="bold">
        {t("header")}
      </Text>
      <Container position="relative" maxW="container.xl">
        <ChakraSwiper
          loop={comments.length > 3}
          maxWidth={{ md: "600px", lg: "800", xl: "952px" }}
          sx={{
            "&>div>.swiper-college-comment>div:first-of-type": {
              width: isMini ? "240px" : "304px !important",
            },
            "&>div>.swiper-college-comment": {
              justifyContent: "center",
              width: "full",
              display: "flex",
              paddingTop: "20px !important",
              height: "auto",
            },
          }}
          width="full"
          spaceBetween={20}
          slidesPerView={slidesPerViews}
          modules={[Navigation]}
          onBeforeInit={(swiper) => {
            swiperRef.current = swiper;
          }}
          {...(isMini && { centeredSlides: true })}
          position="static"
        >
          {comments.length > 3 && <NavigationButtons swiperRefObject={swiperRef} />}
          {comments?.map((comment) => (
            <SwiperSlide className="swiper-college-comment" key={comment.id}>
              <VStack justifyContent="space-between" padding="20px" borderRadius="8px" position="relative">
                <VStack width="full">
                  <Img
                    height="full"
                    width="full"
                    src={BorderImage.src}
                    position="absolute"
                    left={0}
                    right={0}
                    top={0}
                    bottom={0}
                    alt="image border"
                    filter="opacity(.3)"
                  />
                  <Img
                    position="absolute"
                    width={{ base: "80px", md: "100xp" }}
                    height={{ base: "80px", md: "100xp" }}
                    top={{ base: "-40px", md: "-50px" }}
                    transform={{ base: "translateY(20px)", md: "translateY(25px)" }}
                    src={comment.image}
                    right="30px"
                    borderRadius="full"
                    border="1px solid"
                    borderColor={borderColor}
                    alt="commenter person"
                  />
                  <Box paddingTop="20px" width="full">
                    <Img mr="auto" width={{ base: "32px", md: "43px" }} src={QuotMarkImage.src} alt="quote" />
                  </Box>
                  <Text textAlign="right" width="full" lineHeight="150%">
                    {comment.text}
                  </Text>
                </VStack>
                <Text width="full" textAlign="left" fontSize={{ base: "sm", md: "md" }} fontWeight="bold">
                  {comment.name}
                </Text>
              </VStack>
            </SwiperSlide>
          ))}
        </ChakraSwiper>
      </Container>
    </VStack>
  );
};
