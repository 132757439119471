import type { GetServerSideProps } from "next";
import Head from "next/head";
import { gql } from "graphql-request";
import { createServerSideGraphQLClient } from "graphql/server-side-client";
import { useMemo } from "react";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import type { InternationalCollegePLPIndexQuery } from "@/gql-types";
import { getGlobalServerValues, GlobalServerValuesProp } from "@/common/components/GlobalServerValuesProvider";
import { InternationalCollegeIndex } from "@/modules/international/college/pages/InternationalCollegeIndex";
import { NavbarPage, useNavbarActivePage } from "@/common/hooks/useNavbar";
import { PlpContextProvider } from "@/modules/college/plp/context";
import { InternationalCollegePLPProps } from "@/modules/international/college/types";
import { InternationalLayout } from "@/modules/international/contest/components/InternationalLayout";
import { AKWAD_TITLE } from "@/modules/international/common/utils";
import { InternationalFooter } from "@/modules/international/common/components/InternationalFooter";
import { CollegePLPProps } from "@/modules/college/plp/types";

const Page = ({ plp, international }: InternationalCollegePLPProps) => {
  useNavbarActivePage(NavbarPage.DeveloperCollege);

  const plpData: CollegePLPProps = useMemo(() => ({ plp }), [plp]);

  return (
    <InternationalLayout internationalSite={international.international_site}>
      <Head>
        <title>{AKWAD_TITLE}</title>
      </Head>
      <PlpContextProvider plpData={plpData}>
        <InternationalCollegeIndex
          comments={international.college_comments}
          seoContent={international.seo_content}
          colleges={international.colleges}
          myColleges={plp.my_colleges}
        />
      </PlpContextProvider>
    </InternationalLayout>
  );
};

export const getServerSideProps: GetServerSideProps<GlobalServerValuesProp> = async (ctx) => {
  const client = createServerSideGraphQLClient(ctx);

  const data = await client.request<InternationalCollegePLPIndexQuery>(
    gql`
      query InternationalCollegePLPIndexQuery {
        user {
          ...GlobalServerCurrentUserFragment
        }
        international {
          seo_content {
            ...PLPSeoSectionFragment
          }
          colleges {
            id
            pk
            name
            name_description
            url_name
            user_count
            has_enabled_new_landpage_for_registered_user
            tag_text
            card_image_url
            light_large_card_image_url
            dark_normal_card_image_url
            dark_large_card_image_url
          }
          college_comments {
            id
            name
            image
            text
          }
          international_site {
            ...InternationalFooterFragment
            language
          }
        }
        college {
          plp {
            ...InternationalPLPIndexFragments
          }
        }
      }
      ${getGlobalServerValues.fragments.currentUser}
      ${InternationalCollegeIndex.fragment.landpage}
      ${InternationalFooter.fragments.site}
    `,
  );

  const {
    user,
    international,
    college: { plp },
  } = data;
  const globalServerValues = getGlobalServerValues(ctx, user);

  return {
    props: {
      globalServerValues,
      plp,
      international,
      ...(await serverSideTranslations(international.international_site.language, ["common", "college"])),
    },
  };
};

export default Page;
