import { useRef, useState } from "react";
import { VStack, Heading, SimpleGrid, GridItem, Button, Container } from "@chakra-ui/react";
import { gql } from "graphql-request";
import { useTranslation } from "next-i18next";
import { MyCollegesPlpFragment } from "@/gql-types";
import { useSSRBreakpointValue } from "@/common/hooks/useSSRBreakpointValue";
import { MiniMyColleges } from "./MiniMyColleges";
import { CollegeCard } from "./CollegeCard";

export const MyColleges = ({ my_colleges: myColleges }: MyCollegesPlpFragment) => {
  const isMini = useSSRBreakpointValue([true, null, false], { ssr: true });
  const [isShowAll, setShowAll] = useState(false);
  const { t } = useTranslation("college", { keyPrefix: "plp.myColleges" });
  const threshold = 2;

  const headingRef = useRef<HTMLHeadingElement>();
  return (
    <VStack w="full" alignItems="start" backgroundColor="gray.100">
      <Container maxWidth="container.xl" px={[5, null, 4]} paddingBlock={{ base: "24px", md: "50px" }}>
        <Heading
          color="gray.900"
          ref={headingRef}
          mb={["10px", null, "30px"]}
          fontSize={["xl", null, "36px"]}
          fontWeight="700"
        >
          {t("yourPurchasedCourses")}
        </Heading>
        {isMini ? (
          <MiniMyColleges my_colleges={myColleges} />
        ) : (
          <>
            <SimpleGrid width="full" pb="20px" columns={{ base: 1, lg: 2 }} gap="12px">
              {myColleges.slice(0, isShowAll ? myColleges?.length : threshold).map((pao) => (
                <GridItem key={pao.college.pk}>
                  <CollegeCard pao={pao} />
                </GridItem>
              ))}
            </SimpleGrid>
            {myColleges.length > threshold && (
              <Button
                fontSize="lg"
                fontWeight="bold"
                variant="outline"
                colorScheme="gray"
                width="fit-content"
                marginInline="auto !important"
                display="flex"
                alignSelf="center"
                onClick={() => {
                  if (isShowAll) {
                    headingRef.current.scrollIntoView({ behavior: "smooth" });
                  }
                  setShowAll((prev) => !prev);
                }}
              >
                {t("view")} {isShowAll ? t("less") : t("more")}
              </Button>
            )}
          </>
        )}
      </Container>
    </VStack>
  );
};

MyColleges.fragments = {
  collegePLP: gql`
    fragment MyCollegesPlpFragment on CollegePLPType {
      my_colleges {
        passed_percent
        is_suspended
        college {
          pk
          name
          name_description
          card_image_url
          dark_normal_card_image_url
          url_name
          has_enabled_new_landpage_for_registered_user
        }
      }
    }
  `,
};
